@import '../../assets/less/base.less';

.top_main {
    background: url(../../assets/hero_bg.png) center no-repeat;
    background-size: 100% 100%;
    zoom: 1;
    .topbar {
        height: 120px;
        line-height: 120px;
        background: hsla(0, 0%, 100%, .1);
        .nav_bar {
            width: 960px;
            margin: 0 auto;
            // border: 1px solid black;
            .logo_region{
                width:  200px;
                text-align: center;
                img {
                    width:  68px;
                    height: 68px;
                    border-radius: 10px;
                    vertical-align: middle;
                }
                span {
                    color: white;
                    font-size: 30px;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
        }
    }
    .main {
        width: 960px;
        margin: 0 auto;
        .smart {
            width: 450px;
            height: 450px;
            float: left;
            color: white;
            margin-top: 90px;
            .title {
                font-size: 45px;;
            }
            .mark {
                font-weight: 200;
                font-size: 18px;
                margin-top: 30px;
            }
            .platform {
                margin-top: 30px;
                color: black;
                line-height: 55px;
                .android {
                    width: 220px;
                    height: 55px;
                    background-color: white;
                    border-radius: 30px;
                    color: inherit;
                    text-decoration: none;
                    float: left;
                    text-align: center;
                    img {
                        height: 25px;
                        vertical-align: middle;
                    }
                    span {
                        font-size: 18px;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
                .ios {
                    width: 220px;
                    height: 55px;
                    background-color: white;
                    border-radius: 30px;
                    color: inherit;
                    text-decoration: none;
                    float: right;
                    text-align: center;
                    // visibility: hidden;
                    img {
                        height: 25px;
                        vertical-align: middle;
                    }
                    span {
                        font-size: 18px;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
            }
        }
        .exhibit {
            // border: 1px solid black;
            width: 450px;
            height: 540px;
            float: right;
            text-align: right;
            img {
                margin-top: 20px;
                height: 520px;
            }
        }
    }
}


.feature {
    // border: 1px solid black;
    // width: 960px;
    // margin: 0 auto;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
    .groom, .location, .talk, .soul_match{
        // border: 1px solid black;
        width: 220px;
        height: 200px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        .title_region {
            // border: 1px solid black;
            vertical-align: middle;
            img {
                width: 50px;
                vertical-align: middle;
            }
            span {
                vertical-align: middle;
                font-size: 24px;
                color: #383B3C;
                font-weight: 1;
                margin-left: 10px;
            }
        }
        .mark{
            font-size: 18px;
            margin-top: 30px;
            color: #888F94;
            font-weight: 1;
        }
    }
    .location, .talk, .soul_match {
        margin-left: 30px;
    }
}
.driver {
    width: 1200px;
    height: 1px;
    margin: 0 auto;
    background-color: #CBCBCB;
}
.goal {
    // border: 1px solid black;
    // width: 960px;
    // margin: 0 auto;
    .goal_title{
        margin-top: 60px;
        text-align: center;
        .title{
            font-size: 38px;
            font-weight: 1;
        }
        .mark{
            margin-top: 10px;
            font-size: 18px;
            font-weight: 1;
        }
    }
    .goal_img{
        margin-top: 30px;
        text-align: center;
        img{
            width: 292px;
        }
        .man{
            margin-left: 40px;
        }
        .cup{
            margin-left: 40px;
        }
    }
}
.footer{
    text-align: center;
    background-color: #F4F4F4;
    color: #979CA2;
    font-size: 12px;
    margin-top: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    .level2 {
        margin-top: 4px;
        .ll {
            margin-left: 10px;
        }
        a {
            color: #979CA2;
        }
    }
    .level3 {
        margin-top: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        .ll {
            margin-left: 10px;
        }
        a {
            color: #979CA2;
        }
        .beian {
            .icon {
                float:left;
            }
            .num {
                float:left;
                height:20px;
                line-height:20px;
                margin: 0px 0px 0px 5px; 
                color:#939393;
            }
        }
    }
}



