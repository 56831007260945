

/*开启haslayout*/
.clearfix{
    *zoom: 1;
}
  
  /*ie 6 7 不支持伪元素*/
.clearfix:after{
    content: "";
    display: block;
    clear: both;
}